<template>
  <div class="pt-10">
    <vx-card>
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'parent-dashboard'}">Dashboard</router-link>
            <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                          points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
          </li>
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'guardian-list'}">Guardians</router-link>
            <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                          points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class="">Edit Guardian</a>
          </li>
        </ul>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <div class="p-6">

        <!-- Title -->
        <div class="vx-col w-full mt-5">
          <label class="">Title</label>
          <v-select :options="titleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="titleFilter"
                    class="mb-4 md:mb-0"/>
        </div>

        <vs-input label="Full Name" v-model="fullName" class="mt-5 w-full" name="fullName" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first('fullName') }}</span>

        <vs-input label="Relation to child" v-model="relationToChild" class="mt-5 w-full" name="relationToChild"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('relationToChild')">{{ errors.first('relationToChild') }}</span>


        <vs-input class="w-full mt-5" v-model="email" name="email" v-validate="'required|email'" label="Email"/>
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>


        <vs-input label="Mobile Number" v-model="contactNumber" class="mt-5 w-full" name="contactNumber"
                  />
        <span class="text-danger text-sm" v-show="errors.has('contactNumber')">{{
            errors.first('contactNumber')
          }}</span>

        <!-- <vs-input label="Home Number" v-model="homeNumber" class="mt-5 w-full" name="homeNumber"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('homeNumber')">{{ errors.first('homeNumber') }}</span>

        <vs-input label="Work Number" v-model="workNumber" class="mt-5 w-full" name="workNumber"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('workNumber')">{{ errors.first('workNumber') }}</span> -->

        <vs-input label="Street Address" v-model="streetAddress" class="mt-5 w-full" name="streetAddress"
                  />
        <span class="text-danger text-sm" v-show="errors.has('streetAddress')">{{
            errors.first('streetAddress')
          }}</span>

        <vs-input label="Town/City" v-model="town" class="mt-5 w-full" name="town" />
        <span class="text-danger text-sm" v-show="errors.has('town')">{{ errors.first('town') }}</span>

        <div class="vx-col w-full mt-5">
          <label class="">Country</label>
          <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="countryFilter"
                    class="mb-4 md:mb-0"/>
        </div>

        <div class="vx-col w-full mt-5">
          <label class="">State</label>
          <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="stateFilter"
                    class="mb-4 md:mb-0"/>
        </div>
        <vs-input label="Postcode" v-model="postCode" class="mt-5 w-full" name="postCode" />
        <span class="text-danger text-sm" v-show="errors.has('postCode')">{{ errors.first('postCode') }}</span>

         <label class="vs-input--label mt-5">Account Status</label>
          <vs-switch v-model="status"/>
          {{ status ? 'Active' : 'Inactive' }}

        <div class="flex flex-wrap items-center p-6" slot="footer">
          <vs-button class="mr-6 ml-auto" @click="submitData" :disabled="!isFormValid">Save</vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import vSelect from 'vue-select';
  import _ from 'lodash';

  export default {

    data() {
      return {

        /*Add Start*/
        titleOptions: [
          {label: 'Mr.', value: 'Mr.'},
          {label: 'Mrs.', value: 'Mrs.'},
          {label: 'Miss.', value: 'Miss.'}
        ],
        titleFilter: {label: 'Select Title', value: ''},

        countryOptions: [],
        stateOptions: [],
        countryFilter: {label: 'All', value: 'all'},
        stateFilter: {label: 'All', value: 'all'},

        status: true,

        fullName: "",
        relationToChild: "",
        email: "",
        contactNumber: "",
        workNumber: "",
        // homeNumber: "",
        streetAddress: "",
        town: "",
        postCode: "",
        guardianId: this.$route.params.id,

        /*Add End*/
        childId: null,
      };
    },
    methods: {
      ...mapActions("parent", [
        "updateGuardian",
        "guardianDetail"
      ]),

      ...mapActions("country", [
        "getAllCountries",
      ]),

      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },

      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data = new FormData();
            data.append('id', this.guardianId);
            data.append('title', this.titleFilter.value);
            data.append('relationToChild', this.relationToChild);
            data.append('fullName', this.fullName);
            data.append('mobileNumber', this.contactNumber);
            // data.append('homeNumber', this.homeNumber);
            // data.append('workNumber', this.workNumber);
            data.append('email', this.email);
            data.append('address.street', this.streetAddress);
            data.append('address.suburb', this.town);
            data.append('address.postCode', this.postCode);
            data.append('address.state', this.stateFilter.value);
            data.append('address.country', this.countryFilter.value);
            data.append('userType', "guardians");
            data.append('role', "parent");
            data.append('status', this.status);
            data.append('childId', this.childId);

            if (this.guardianId !== null) {

              this.$vs.loading()
              this.updateGuardian(data)
                .then((res) => {
                  this.$vs.loading.close()
                  this.showMessage("Success", "Parent updated successfully.", "success");
                  this.$router.push({name: 'guardian-list'});
                })
                .catch(err => {
                  this.$vs.loading.close()
                  this.showMessage("Failed", "Failed to add parent", "danger");
                })
            }
          }
        })
      },
      async initValues(data) {
        this.fullName = data.parentDetail.fullName;
        this.relationToChild = data.parents.relationToChild;
        this.email = data.parentDetail.email;
        this.contactNumber = data.parentDetail.mobileNumber;
        // this.workNumber = data.parentDetail.workNumber;
        // this.homeNumber = data.parentDetail.homeNumber;
        this.streetAddress = data.parentDetail.address.street;
        this.town = data.parentDetail.address.suburb;
        this.postCode = data.parentDetail.address.postCode;
        this.status = (data.parentDetail.accountStatus == "Inactive") ? false:true;

        let selectedCountry = _.filter(this.countryOptions, {states: [{value: data.parentDetail.address.state}]});

        this.countryFilter = selectedCountry
        if (this.countryFilter) {
          this.stateOptions = this.countryFilter.states;
          this.stateFilter = {label: data.parentDetail.address.state, value: data.parentDetail.address.state};
        }

        this.titleFilter = {label: data.parentDetail.title, value: data.parentDetail.title};
        let self = this;
        self.stateOptions = await self.countryFilter[0].states;

      }
    },
    watch: {
      countryFilter(obj) {
        if (obj) {
          this.stateOptions = obj.states;
        }
      },
    },
    async created() {
      this.childId = this.currentChild._id;

      await this.getAllCountries()
        .then((res) => {
          this.countryOptions = this.$store.state.country.countryOptions;
        })
        .catch(err => {
        })

      let data = {
        guardianId: this.guardianId,
        childId: this.childId
      }
      await this.guardianDetail(data)
        .then((res) => {
          let child = res.data.data
          this.initValues(child);
        })
        .catch(err => {
        })

    },
    computed: {
      isFormValid() {

        return !this.errors.any()

          && this.titleFilter.value
          && this.fullName
          && this.email
      },
      currentChild() {
        return this.$store.state.AppParentSelectedChild;
      }
    },
    components: {
      vSelect,
    }

  }
</script>
